import { useState, useEffect } from 'react';
import { Row, Col, Panel, List, FlexboxGrid, Button } from 'rsuite';
import { rang, strokovnoPodrocje, jeziki, vloga } from "./data";
import EditIcon from '@rsuite/icons/Edit.js';
import TrashIcon from '@rsuite/icons/Trash.js';

// const EditableField = ({ itemKey, dataKey, onChange, ...props }) => {
//     const editing = itemKey.status === 'EDIT';
//     return (
//         <div {...props} className={editing ? 'table-content-editing' : ''}>
//             {editing ? (
//             <input
//                 className="rs-input"
//                 defaultValue={rowData[dataKey]}
//                 onChange={event => {
//                     onChange && onChange(rowData.id, dataKey, event.target.value);
//                 }}
//             />
//             ) : (
//                 <div>{rowData[dataKey]}</div>
//             )}
//         </div>
//     );
// };

// const ActionField = ({ rowData, dataKey, onClick, ...props }) => {
//     return (
//         <Button
//             {...props}
//             style={{ padding: '6px' }}
//             appearance="link"
//             onClick={() => {
//                 onClick(rowData.id);
//             }}
//         >
//             {rowData.status === 'EDIT' ? 'Shrani' : 'Uredi'}+
//         </Button>
//     );
// };

export const AdminData = () => {
    const [dataRang, setDataRang] = useState(rang);
    const [dataJeziki, setDataJeziki] = useState(jeziki);
    const [dataStrokovnoPodrocje, setDataStrokovnoPodrocje] = useState(strokovnoPodrocje);
    const [dataVloga, setDataVloga] = useState(vloga);
    // const [data, setData] = useState(defaultData);

    // const handleChange = (id, key, value) => {
    //     const nextData = Object.assign([], data);
    //     nextData.find(item => item.id === id)[key] = value;
    //     setDataRang(nextData);
    // };
    // const handleEditState = id => {
    //     const nextData = Object.assign([], data);
    //     const activeItem = nextData.find(item => item.id === id);
    //     activeItem.status = activeItem.status ? null : 'EDIT';
    //     setDataRang(nextData);
    // };

    const handleSortEnd = ({ oldIndex, newIndex }) =>
        setDataRang(prvData => {
            const moveData = prvData.splice(oldIndex, 1);
            const newData = [...prvData];
            newData.splice(newIndex, 0, moveData[0]);
            return newData;
        }, []);

	return (
		<Row style={{marginTop: "2rem"}}>
            <Col style={{width: "25%"}}>
                <Panel header="Rang" shaded>
                    <List size="sm" sortable onSort={handleSortEnd}>
                        {dataRang.map(({ value, disabled }, index) => (
                            <List.Item key={index} index={index}>
                                <FlexboxGrid>
                                    <FlexboxGrid.Item
                                        colspan={18}
                                    >
                                        {value}
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item
                                        colspan={6}
                                    >
                                        <a href="#"><EditIcon /></a> 
                                        <span style={{ padding: 5 }}>|</span>
                                        <a href="#"><TrashIcon /></a>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </List.Item>
                        ))}
                    </List>
                </Panel>
            </Col>
            <Col style={{width: "25%"}}>
                <Panel header="Jeziki" shaded>
                    <List size="sm" sortable onSort={handleSortEnd}>
                        {dataJeziki.map(({ value, disabled }, index) => (
                            <List.Item key={index} index={index}>
                                <FlexboxGrid>
                                    <FlexboxGrid.Item
                                        colspan={18}
                                    >
                                        {value}
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item
                                        colspan={6}
                                    >
                                        <a href="#"><EditIcon /></a>
                                        <span style={{ padding: 5 }}>|</span>
                                        <a href="#"><TrashIcon /></a>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </List.Item>
                        ))}
                    </List>
                </Panel>
            </Col>
            <Col style={{width: "25%"}}>
                <Panel header="Strokovno področje" shaded>
                    <List size="sm" sortable onSort={handleSortEnd}>
                        {dataStrokovnoPodrocje.map(({ value, disabled }, index) => (
                            <List.Item key={index} index={index}>
                                <FlexboxGrid>
                                    <FlexboxGrid.Item
                                        colspan={18}
                                    >
                                        {value}
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item
                                        colspan={6}
                                    >
                                        <a href="#"><EditIcon /></a>
                                        <span style={{ padding: 5 }}>|</span>
                                        <a href="#"><TrashIcon /></a>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </List.Item>
                        ))}
                    </List>
                </Panel>
            </Col>
            <Col style={{width: "25%"}}>
                <Panel header="Vloga" shaded>
                    <List size="sm" sortable onSort={handleSortEnd}>
                        {dataVloga.map(({ value, disabled }, index) => (
                            <List.Item key={index} index={index}>
                                <FlexboxGrid>
                                    <FlexboxGrid.Item
                                        colspan={18}
                                    >
                                        {value}
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item
                                        colspan={6}
                                    >
                                        <a href="#"><EditIcon /></a>
                                        <span style={{ padding: 5 }}>|</span>
                                        <a href="#"><TrashIcon /></a>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </List.Item>
                        ))}
                    </List>
                </Panel>
            </Col>  
		</Row>
	)
}

export default AdminData;