import { useState } from "react";
import AdminData from "./AdminData";
import MembersDataDisplay from "./MembersDataDisplay";
import HomeIcon from '@rsuite/icons/legacy/Home';
import CogIcon from '@rsuite/icons/legacy/Cog';
import { Navbar, Nav, Container, Header, Content, Footer, Grid, Row, Col, Table, Button, Modal, Form, DatePicker, RadioGroup, Radio, Divider } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import './App.css';

const App = () => {
    const [activeKey, setActiveKey] = useState("1");

    return (
        <Container>
            <Header>
                <Navbar activeKey={activeKey} onSelect={setActiveKey} >
                <Navbar.Brand as={('div')} >DZTPS - člani</Navbar.Brand>
                <Nav onSelect={setActiveKey} activeKey={activeKey}>
                    <Nav.Item eventKey="1" icon={<HomeIcon />}>
                        Vsi člani
                    </Nav.Item>
                    <Nav.Item eventKey="2" icon={<CogIcon />}>
                        Parametri
                    </Nav.Item>
                </Nav>
                </Navbar>
            </Header>
            <Content>
                <main>
                <Grid style={{marginTop: "2rem"}}>
                    <Row>
                        <Col>
                            <h3>DZTPS - člani</h3>
                        </Col>
                        <Col>
                            <Button appearance="ghost" >Odjava</Button>
                        </Col>
                    </Row>
                    {activeKey === "1" ? <MembersDataDisplay /> : <AdminData />}
                    </Grid>
                </main>
            </Content>
            <Footer>
            </Footer>
        </Container>
    )
}

export default App