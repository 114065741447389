import { useState, useEffect } from 'react';
import axios from "axios";
import { Container, Header, Content, Footer, Grid, Row, Col, Table, Button, Modal, Form, DatePicker, RadioGroup, Radio, Divider, InputPicker, useToaster, Message, CheckPicker } from 'rsuite';
import { rang, strokovnoPodrocje, jeziki, vloga } from "./data";

const { Column, HeaderCell, Cell } = Table;

const EditableCellStrokovnoPodrocje = ({ rowData, dataKey, onChange, ...props }) => {
	const editing = rowData.status === 'EDIT';
	return (
		<Cell {...props} className={editing ? 'table-content-editing' : ''}>
		{editing ? (
			<CheckPicker
			cleanable={false}
			data={strokovnoPodrocje}
			value={rowData[dataKey]}
			onChange={value => {
				onChange && onChange(rowData.id, dataKey, value);
			}}
			/>
		) : (
			<span className="table-content-edit-span">
				{rowData[dataKey].map((x, i) => <span key={i}>{x}<br /></span>)
				}
			</span>
		)}
		</Cell>
	);
};

const EditableCellVloga = ({ rowData, dataKey, onChange, ...props }) => {
	const editing = rowData.status === 'EDIT';
	return (
		<Cell {...props} className={editing ? 'table-content-editing' : ''}>
		{editing ? (
			<CheckPicker
			cleanable={false}
			data={vloga}
			value={rowData[dataKey]}
			onChange={value => {
				onChange && onChange(rowData.id, dataKey, value);
			}}
			/>
		) : (
			<span className="table-content-edit-span">
				{rowData[dataKey].map((x, i) => <span key={i}>{x}<br /></span>)
				}
			</span>
		)}
		</Cell>
	);
};

const EditableCellRang = ({ rowData, dataKey, onChange, ...props }) => {
	const editing = rowData.status === 'EDIT';
	return (
		<Cell {...props} className={editing ? 'table-content-editing' : ''}>
		{editing ? (
			<InputPicker
			cleanable={false}
			data={rang}
			value={rowData[dataKey]}
			onChange={value => {
				onChange && onChange(rowData.id, dataKey, value);
			}}
			/>
		) : (
			<span className="table-content-edit-span">{rowData[dataKey]}</span>
		)}
		</Cell>
	);
};

const EditableCellLanguage = ({ rowData, dataKey, onChange, ...props }) => {
	const editing = rowData.status === 'EDIT';
	return (
		<Cell {...props} className={editing ? 'table-content-editing' : ''}>
		{editing ? (
			<InputPicker
			cleanable={false}
			data={jeziki}
			value={rowData[dataKey]}
			onChange={value => {
				onChange && onChange(rowData.id, dataKey, value);
			}}
			/>
		) : (
			<span className="table-content-edit-span">{rowData[dataKey]}</span>
		)}
		</Cell>
	);
};

const EditableCellLicenca = ({ rowData, dataKey, onChange, ...props }) => {
	const editing = rowData.status === 'EDIT';
	return (
		<Cell {...props} className={editing ? 'table-content-editing' : ''}>
		{editing ? (
			<InputPicker
			cleanable={false}
			data={[{
				"value": "da",
				"label": "da"
			},
			{
				"value": "ne",
				"label": "ne"
			}]}
			value={rowData[dataKey]}
			onChange={value => {
				onChange && onChange(rowData.id, dataKey, value);
			}}
			/>
		) : (
			<span className="table-content-edit-span">{rowData[dataKey]}</span>
		)}
		</Cell>
	);
};

const ActionCell = ({ rowData, dataKey, onClick, ...props }) => {
	return (
		<Cell {...props} style={{ padding: '6px' }}>
			<Button
				appearance="link"
				onClick={() => {
				onClick(rowData.id);
				}}
			>
				{rowData.status === 'EDIT' ? 'Save' : 'Edit'}
			</Button>
		</Cell>
	);
};

export const MembersDataDisplay = () => {
	const [open, setOpen] = useState(false);
    const [loader, setLoader] = useState(true);
	const [userData, setUserData] = useState({
		id: 0,
		ime: "",
		priimek: "",
		telefondoma: "",
		telefonmobi: "",
		telefonsluzba: "",
		email: "",
		spletnastran: ""
	});
	const [singleUserData, setSingleUserData] = useState({
		"id": 0,
        "ime": "",
        "priimek": "",
        "telefondoma": "",
        "telefonmobi": "",
        "telefonsluzba": "",
        "email": "",
        "spletnastran": "",
        "prevodi": [
        ]
	});
	const [userPrevodi, setUserPrevodi] = useState([]);
	  
	const maxPrevodiLength = userPrevodi.reduce((max, obj) => {
		if (obj.strokovnopodrocje && obj.strokovnopodrocje.length > max) {
			return obj.strokovnopodrocje.length;
		} else {
			return (max);
		}
	}, 0);

	const baseURL = "https://iskalnik.dztps.si/express_backend/api/getAll";

	useEffect(() => {
		axios.get(baseURL).then((response) => {
			setUserData(response.data);
		});
	}, []);

	const baseURL2 = "https://iskalnik.dztps.si/express_backend/api/get/user";

	const toaster = useToaster();
    const messageError = <Message type="error" header="Prišlo je do napake!">Prosimo poskusite znova.</Message>

	const getUserData = (id) => {
        setLoader(true);
        setOpen(!open);
        axios.get(`${baseURL2}/${id}`)
        .then((response) => {
            setSingleUserData(response.data);
			setUserPrevodi(response.data.prevodi);
            setTimeout(() => {
                setLoader(false);
            }, 250);
        }).catch(error => {
            toaster.push(messageError, { placement: "topCenter" })
            console.error("error: ", error);
            setLoader(false);
        });
    }

	const addNewUser = () => {
        setLoader(true);
        setOpen(!open);
		setLoader(false);
    }

  	const handleChange = (id, key, value) => {
		const nextData = Object.assign([], userPrevodi);
		nextData.find(item => item.id === id)[key] = value;
		setUserPrevodi(nextData);
  	};

	const handleEditState = id => {
		const nextData = Object.assign([], userPrevodi);
		const activeItem = nextData.find(item => item.id === id);
		activeItem.status = activeItem.status ? null : 'EDIT';
		setUserPrevodi(nextData);
	};

	const handleFormChange = () => {
	}

  	const handleOpen = (id) => {
    	setOpen(true);
  	}

  	const handleClose = () => {
		setOpen(false);
		setSingleUserData({
			"id": 0,
			"ime": "",
			"priimek": "",
			"telefondoma": "",
			"telefonmobi": "",
			"telefonsluzba": "",
			"email": "",
			"spletnastran": "",
			"prevodi": [
			]
		});
		setUserPrevodi([]);
	};

	return (
		<div style={{marginTop: "2rem"}}>
			<Button appearance="ghost" onClick={addNewUser}>Dodaj novega člana</Button>
			<Table virtualized data={userData} height={600} >
				<Column width={130} align="center" fixed>
					<HeaderCell>Id (v bodoče ID izkaznice)</HeaderCell>
					<Cell dataKey="id" />
				</Column>

				<Column width={130} fixed>
					<HeaderCell>Ime</HeaderCell>
					<Cell dataKey="ime" />
				</Column>

				<Column width={200} fixed>
					<HeaderCell>Priimek</HeaderCell>
					<Cell dataKey="priimek" />
				</Column>

				<Column width={200}>
					<HeaderCell>Datum rojstva</HeaderCell>
					<Cell dataKey="dateofbirth" />
				</Column>

				<Column width={130}>
					<HeaderCell>Objava v iskalniku</HeaderCell>
					<Cell dataKey="memberfinderpublish" />
				</Column>

				<Column width={130} align='center'>
					<HeaderCell>Več</HeaderCell>
					<Cell style={{ padding: '6px' }}>
						{rowData => (
							<Button appearance="ghost" onClick={() => getUserData(rowData.id)}>
							{/* <Button appearance="ghost" onClick={() => handleOpen(rowData.id_user)}> */}
								Uredi
							</Button>
						)}
					</Cell>
				</Column>
			</Table>
			<Modal size="lg" open={open} onClose={handleClose}>
				<Modal.Header>
					<Modal.Title>Član - {singleUserData.ime} {singleUserData.priimek}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onChange={handleFormChange}>
						<Grid style={{width: "100%"}}>
							<Row>
							<Divider>Osebni podatki</Divider>
								<Col>
									<Form.Group controlId="user_firstname">
										<Form.ControlLabel>Ime:</Form.ControlLabel>
										<Form.Control defaultValue={singleUserData.ime} key={singleUserData.ime} name="user_firstname"  />
									</Form.Group>
									
									<Form.Group controlId="user_idcard">
										<Form.ControlLabel>ID izkaznice:</Form.ControlLabel>
										<Form.Control name="user_idcard"  />
									</Form.Group>
									<Form.Group controlId="user_dateofmembership">
										<Form.ControlLabel>Datum včlanitve:</Form.ControlLabel>
										<Form.Control
											name="user_dateofmembership"
											isoWeek
											locale={{
												sunday: 'Ned',
												monday: 'Pon',
												tuesday: 'Tor',
												wednesday: 'Sre',
												thursday: 'Čet',
												friday: 'Pet',
												saturday: 'Sob',
												ok: 'OK'
											}}
											accepter={DatePicker} 
										/>
									</Form.Group>
									<Form.Group controlId="user_addressstreet">
										<Form.ControlLabel>Ulica:</Form.ControlLabel>
										<Form.Control name="user_addressstreet"  />
									</Form.Group>
									<Form.Group controlId="user_addresszip">
										<Form.ControlLabel>Poštna številka:</Form.ControlLabel>
										<Form.Control name="user_addresszip"  />
									</Form.Group>
									<Form.Group controlId="user_addresszippost">
										<Form.ControlLabel>Pošta:</Form.ControlLabel>
										<Form.Control name="user_addresszippost"  />
									</Form.Group>
								</Col>
								<Col>
									<Form.Group controlId="user_lastname">
										<Form.ControlLabel>Priimek:</Form.ControlLabel>
										<Form.Control defaultValue={singleUserData.priimek} key={singleUserData.priimek} name="user_lastname" />
									</Form.Group>
									<Form.Group controlId="user_dateofbirth">
										<Form.ControlLabel>Datum rojstva:</Form.ControlLabel>
										<Form.Control
											name="user_dateofbirth"
											isoWeek
											locale={{
												sunday: 'Ned',
												monday: 'Pon',
												tuesday: 'Tor',
												wednesday: 'Sre',
												thursday: 'Čet',
												friday: 'Pet',
												saturday: 'Sob'
											}}
											accepter={DatePicker}
										/>
									</Form.Group>
									<Form.Group controlId="user_placeofbirth">
										<Form.ControlLabel>Kraj rojstva:</Form.ControlLabel>
										<Form.Control name="user_placeofbirth"  />
									</Form.Group>
									<Form.Group controlId="user_citizenship">
										<Form.ControlLabel>Državljanstvo:</Form.ControlLabel>
										<Form.Control name="user_citizenship"  />
									</Form.Group>
									<Form.Group controlId="user_mothertongue">
										<Form.ControlLabel>Materni jezik:</Form.ControlLabel>
										<Form.Control name="user_mothertongue"  />
									</Form.Group>
									<Form.Group controlId="user_education">
										<Form.ControlLabel>Šolska izobrazba:</Form.ControlLabel>
										<Form.Control name="user_education"  />
									</Form.Group>
									<Form.Group controlId="user_occupation">
										<Form.ControlLabel>Osnovni poklic:</Form.ControlLabel>
										<Form.Control name="user_occupation"  />
									</Form.Group>
								</Col>
							</Row>
							
							<Divider>Kontaktni podatki</Divider>

							<Row>
								<Col>
									<Form.Group controlId="user_phonehome">
										<Form.ControlLabel>Telefon doma:</Form.ControlLabel>
										<Form.Control defaultValue={singleUserData.telefondoma} key={singleUserData.telefondoma} name="user_phonehome" />
									</Form.Group>
									<Form.Group controlId="user_phonework">
										<Form.ControlLabel>Telefon služba:</Form.ControlLabel>
										<Form.Control defaultValue={singleUserData.telefonsluzba} key={singleUserData.telefonsluzba} name="user_phonework" />
									</Form.Group>
									<Form.Group controlId="user_phonemobile">
										<Form.ControlLabel>Telefon mobi:</Form.ControlLabel>
										<Form.Control defaultValue={singleUserData.telefonmobi} key={singleUserData.telefonmobi} name="user_phonemobile" />
									</Form.Group>
								</Col>
								<Col>
									<Form.Group controlId="user_email">
										<Form.ControlLabel>E-pošta:</Form.ControlLabel>
										<Form.Control defaultValue={singleUserData.email} key={singleUserData.email} name="user_email" />
									</Form.Group>
									<Form.Group controlId="user_webpage">
										<Form.ControlLabel>Spletna stran:</Form.ControlLabel>
										<Form.Control defaultValue={singleUserData.spletnastran} key={singleUserData.spletnastran} name="user_webpage" />
									</Form.Group>						
									<Form.Group controlId="user_memberfinderpublish">
										<Form.ControlLabel>Objava v iskalniku:</Form.ControlLabel>
										<Form.Control name="user_memberfinderpublish" accepter={RadioGroup} inline>
											<Radio value="da">DA</Radio>
											<Radio value="ne">NE</Radio>
										</Form.Control>
									</Form.Group>
								</Col>
							</Row>
						</Grid>

						<Divider>Prevodi</Divider>

						<Table
							height={420}
							data={singleUserData.prevodi}
							rowHeight={40*maxPrevodiLength}
						>
							<Column width={50}>
								<HeaderCell>Rang</HeaderCell>
								<EditableCellRang dataKey="rang" onChange={handleChange} />
							</Column>

							<Column width={100} fullText>
								<HeaderCell>Iz</HeaderCell>
								<EditableCellLanguage dataKey="iz" onChange={handleChange} />
							</Column>

							<Column width={100} fullText>
								<HeaderCell>V</HeaderCell>
								<EditableCellLanguage dataKey="v" onChange={handleChange} />
							</Column>

							<Column width={150} fullText>
								<HeaderCell>Strokovno področje</HeaderCell>
								<EditableCellStrokovnoPodrocje dataKey="strokovnopodrocje" onChange={handleChange} />
							</Column>

							<Column width={150} fullText>
								<HeaderCell>Vloga</HeaderCell>
								<EditableCellVloga dataKey="vloga" onChange={handleChange} />
							</Column>

							<Column width={150}>
								<HeaderCell>Licenca DZTPS</HeaderCell>
								<EditableCellLicenca dataKey="licencaDZTPS" onChange={handleChange} />
							</Column>

							<Column flexGrow={1}>
								<HeaderCell>...</HeaderCell>
								<ActionCell dataKey="id" onClick={handleEditState} />
							</Column>
						</Table>

					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={handleClose} appearance="ghost" color="green">
						Shrani
					</Button>
					<Button onClick={handleClose} appearance="subtle">
						Prekliči
					</Button>
					<br />
					<Button onClick={handleClose} appearance="link" color="red">
						Zbriši uporabnika
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	)
}


export default MembersDataDisplay;